<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">

    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            error: "",
            data: {}
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/reports/general', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.data = r.response
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
    },
    methods: {
    },
}
</script>

<style>

</style>